import { yupResolver } from "@hookform/resolvers/yup";
import { collection, doc, writeBatch } from "firebase/firestore";
import { debounce } from "lodash";
import moment from "moment-timezone";
import type {
  Appointment,
  AppointmentVariant,
} from "practicare/types/appointments.model";
import type { Location } from "practicare/types/location.model";
import type { Room } from "practicare/types/room.model";
import type { UserServiceVariant } from "practicare/types/service.model";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { renderSelectField } from "src/components/forms/renderSelectField";
import { appointmentRepeatOptions } from "src/config/utils";
import { useAuth } from "src/context/auth";
import UILoader from "src/layout/components/ui-loader";
import { subscribeToAllUserAvailabilityData } from "src/redux/reducers/availability";
import { subscribeToCustomersForUser } from "src/redux/reducers/customers";
import { subscribeToVariantsForUser } from "src/redux/reducers/variants";
import type { AppState } from "src/redux/store";
import * as yup from "yup";
import { addDocWithUser, db } from "../../config/firebase";
import { renderDatePicker } from "../forms/renderDatePicker";
import { renderInputField } from "../forms/renderInputField";
import {
  UserServiceVariantOption,
  UserServiceVariantOptionSelected,
} from "../forms/utils";
import type { AppointmentAddModalAppointmentFormData } from "./appointmentAddModal.types";

const locationFromAvailability: {
  location: Location | null;
  room: Room | null;
} = {
  location: null,
  room: null,
};
export const AppointmentAddModalCustomizable = ({
  isOpen,
  closeModal,
}: {
  isOpen: boolean;
  closeModal: () => void;
}) => {
  const { user } = useAuth();
  const { t, i18n } = useTranslation();
  const storeRooms = useSelector((state: AppState) => state.rooms);
  const storeLocations = useSelector((state: AppState) => state.locations);
  const customerState = useSelector((state: AppState) => state.customers);
  const variantsState = useSelector((state: AppState) => state.variants);
  const availabilityState = useSelector(
    (state: AppState) => state.availability
  );
  const [loading, setLoading] = useState(false);
  const [roomsSelectList, setRoomsSelectList] = useState<Room[]>([]);
  const [locationsSelectList, setLocationsSelectList] = useState<Location[]>(
    []
  );
  const formSchema = yup.object().shape({
    dateTime: yup.date().required(t("Required field")),
    customer: yup.object().required(t("Required field")),
    variant: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    room: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    location: yup
      .object()
      .typeError(t("Required field"))
      .required(t("Required field")),
    repeatType: yup.object().nullable(),
    repeats: yup
      .number()
      .integer()
      .min(0)
      .when("repeatType", {
        is: (r: any) => r !== null,
        then: (schema) => schema.required(t("Required field")),
        otherwise: (schema) => schema.nullable(),
      }),
  });

  const defaultValues: AppointmentAddModalAppointmentFormData = {
    dateTime: null,
    customer: null,
    variant: null,
    room: null,
    location: null,
    repeatType: null,
    repeats: 0,
  };

  const {
    reset,
    control,
    setValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues,
    mode: "onSubmit",
    resolver: yupResolver(formSchema) as any,
  });

  const close = () => {
    reset();
    closeModal();
  };

  const createAppointmentData = (
    appointDetails: AppointmentAddModalAppointmentFormData,
    parentId?: string,
    newDate?: Date
  ): Appointment => ({
    appointmentToken: "",
    bookedOnline: false,
    comment: "",
    invoiceMonth: "",
    meetingUrl: "",
    repeatType: appointDetails.repeatType as any,
    repeats: appointDetails.repeats,
    variant: appointDetails.variant as AppointmentVariant,
    appointmentType: null,
    automaticCancelDate: null,
    automaticCancelIfNotPaid: false,
    automaticCancelIfNotPaidHours: 24,
    dateChangeReason: "",
    customer: {
      id: appointDetails.customer?.id || "",
      firstName: appointDetails.customer?.firstName || "",
      lastName: appointDetails.customer?.lastName || "",
      email: appointDetails.customer?.email || "",
      phoneNumber: appointDetails.customer?.phoneNumber || "",
      skype: appointDetails.customer?.skype || "",
      contractSignDate: appointDetails.customer?.contractSignDate || null,
      cancelledAppointments:
        appointDetails.customer?.cancelledAppointments || [],
      comment: appointDetails.customer?.comment || "",
    },
    theraphist: {
      id: user.userData?.id || "",
      firstName: user.userData?.firstName || "",
      lastName: user.userData?.lastName || "",
      avatar: user.userData?.avatar || "",
      email: user.userData?.email || "",
      phoneNumber: user.userData?.phoneNumber || "",
      isHiddenFromCalendar: !!user.userData?.isHiddenFromCalendar,
    },
    room: {
      id: appointDetails.room?.id || "",
      name: appointDetails.room?.name || "",
    },
    location: {
      id: appointDetails.location?.id || "",
      name: appointDetails.location?.name || "",
      address: appointDetails.location?.address || "",
      zipCode: appointDetails.location?.zipCode || "",
      city: appointDetails.location?.city || "",
    },
    dateTime: newDate ? newDate : (appointDetails.dateTime as Date),
    isDeleted: false,
    appointmentStatus: "SCHEDULED",
    paymentType: "ONLINE",
    paymentStatus: "PENDING",
    isNotificationProcessed: false,
    createdBy: {
      id: user.userData?.id || "",
      firstName: user.userData?.firstName || "",
      lastName: user.userData?.lastName || "",
      avatar: user.userData?.avatar || "",
    },
    createdAt: moment().toDate() as any,
    parentId: parentId || "",
  });

  const bouncer = useCallback(
    debounce(async () => {
      setLoading(true);
      try {
        const appointDetails = watch();
        const batch = writeBatch(db);
        const appointmentData = createAppointmentData(appointDetails);
        let parentId = null;
        const docRef = await addDocWithUser(
          collection(db, "appointments"),
          appointmentData,
          user
        );
        parentId = docRef.id;

        if (appointDetails.repeats > 1 && appointDetails.repeatType) {
          for (let i = 1; i < appointDetails.repeats; i++) {
            let count = 1;
            if (appointDetails.repeatType.value === "Tygodniowe") {
              count = 1;
            }
            if (appointDetails.repeatType.value === "Dwutygodniowe") {
              count = 2;
            }
            if (appointDetails.repeatType.value === "Miesięczne") {
              count = 1;
            }
            const newDate = moment(appointDetails.dateTime)
              .add(i * count, appointDetails.repeatType.moment)
              .toDate();
            const repeatedAppointmentData = createAppointmentData(
              appointDetails,
              parentId || "",
              newDate
            );
            batch.set(
              doc(collection(db, "appointments")),
              repeatedAppointmentData
            );
          }
        }

        await batch.commit();
      } catch (e) {
        console.error(e);
        await addDocWithUser(
          collection(db, "errors"),
          { error: JSON.stringify(e), appointDetails: JSON.stringify(watch()) },
          user
        );
      } finally {
        setLoading(false);
        close();
      }
    }, 500),
    [watch, user, close]
  );

  const onSubmit = () => {
    setLoading(true);
    bouncer();
    return true;
  };

  const updateRooms = (location: Location, noClear?: boolean) => {
    const filteredRooms = storeRooms.data.filter(
      (r) => r.location.id === location.id
    );
    setRoomsSelectList(filteredRooms);
    if (noClear) {
      return true;
    }
    setValue("room", null);
    return true;
  };

  const updateDefaultLocationAndRoom = (dateTime: Date | null) => {
    if (!dateTime) {
      locationFromAvailability.location = null;
      locationFromAvailability.room = null;
      return;
    }
    const eventDayOfWeek = moment(dateTime).isoWeekday();
    const eventHour = dateTime ? dateTime.getHours() : new Date().getHours();
    const selectedUserAvailability =
      availabilityState.allUserAvailability.filter((a) => {
        if (
          a.theraphist.id !== user.userData?.id ||
          a.type?.value === "BLOCK" ||
          a.type?.value === "TIME_OFF" ||
          moment(a.endDate)
            .endOf("day")
            .isSameOrBefore(moment(dateTime).startOf("day"))
        ) {
          return false;
        }
        if (
          !(
            moment(dateTime).isSameOrBefore(moment(a.endDate).add(1, "d")) &&
            moment(dateTime).isSameOrAfter(moment(a.startDate).subtract(1, "d"))
          )
        ) {
          return false;
        }
        const availabilityStartHour = (a.startHour as Date)?.getHours();
        const availabilityEndHour = (a.endHour as Date)?.getHours();

        const isSameWeekDay = Number(a.dayOfWeek?.value) === eventDayOfWeek;
        const isWithinTimeSlot =
          availabilityStartHour <= eventHour &&
          eventHour <= availabilityEndHour;
        return isSameWeekDay && isWithinTimeSlot;
      });

    if (selectedUserAvailability.length > 0) {
      locationFromAvailability.room = selectedUserAvailability[0].room as Room;
      locationFromAvailability.location = selectedUserAvailability[0]
        .location as Location;
      defaultValues.room = selectedUserAvailability[0].room as Room;
      defaultValues.location = selectedUserAvailability[0].location as Location;
      setValue("room", selectedUserAvailability[0].room as Room);
      setValue("location", selectedUserAvailability[0].location as Location);
      const selectedRoom = storeRooms.data.find(
        (r) => r.id === selectedUserAvailability[0].room?.id
      ) as Room;
      const selectedLocation = storeLocations.data.find(
        (l) => l.id === selectedUserAvailability[0].location?.id
      ) as Location;
      setRoomsSelectList([selectedRoom]);
      setLocationsSelectList([selectedLocation]);
    } else {
      const selectedLocation = storeLocations.data.find(
        (l) => l.name === "Online" || l.isOnline
      ) as Location;
      const selectedRoom = storeRooms.data.find(
        (r) => r.location.id === selectedLocation?.id
      ) as Room;
      setValue("location", selectedLocation);
      setValue("room", selectedRoom);
      setRoomsSelectList([selectedRoom]);
      setLocationsSelectList([selectedLocation]);
    }
  };

  useEffect(() => {
    if (user.userData) {
      subscribeToCustomersForUser(user.userData.id, false);
      subscribeToVariantsForUser(user.userData.id);
      subscribeToAllUserAvailabilityData(user.userData.id);
    }
  }, [user]);

  useEffect(() => {
    if (isOpen) {
      updateDefaultLocationAndRoom(watch("dateTime"));
    }
  }, [isOpen, availabilityState.updatedAt]);
  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        toggle={() => close()}
        className="modal-dialog-centered"
      >
        <UILoader blocking={loading}>
          <ModalHeader
            className="bg-transparent justify-content-end"
            toggle={() => close()}
          ></ModalHeader>
          <ModalBody className="px-sm-5 mx-50 pb-5">
            <Row
              tag="form"
              className="gy-1 gx-2 mt-75"
              onSubmit={handleSubmit(onSubmit)}
            >
              {renderDatePicker({
                name: "dateTime",
                label: t("Date"),
                required: true,
                control: control,
                errors: errors,
                watch: watch,
                setValue: setValue,
                placeholder: t("Select date"),
                dateFormat: "Y-m-d H:i",
                enableTime: true,
                styles: {
                  hideMargin: true,
                  width50: true,
                },
                onChange: (e: Date[]) => updateDefaultLocationAndRoom(e[0]),
                i18n,
              })}

              {renderSelectField({
                name: "customer",
                label: t("Customer"),
                placeholder: t("Select"),
                isMulti: false,
                control: control,
                required: true,
                errors: errors,
                options: customerState.customersForUser,
                styles: {
                  hideMargin: true,
                  width50: true,
                },
              })}

              {renderSelectField({
                name: "variant",
                placeholder: t("Select"),
                label: t("Service Variant"),
                isMulti: false,
                required: true,
                control: control,
                errors: errors,
                options: variantsState.userData.filter((v) => {
                  if (
                    locationFromAvailability.location &&
                    locationFromAvailability?.location?.name !== "Online"
                  ) {
                    return true;
                  } else {
                    if (!locationFromAvailability.location) {
                      return true;
                    } else {
                      return v.variant.isOnline;
                    }
                  }
                }),
                disabled: !!(variantsState.userData.length === 0),
                getOptionLabelFnc: (option: UserServiceVariant) =>
                  option.variant.name,
                getOptionValueFnc: (option: UserServiceVariant) =>
                  option.id || "",
                components: {
                  Option: UserServiceVariantOption,
                  SingleValue: UserServiceVariantOptionSelected,
                },
                styles: { hideMargin: true },
              })}

              {renderSelectField({
                name: "location",
                placeholder: t("Select"),
                label: t("Location"),
                isMulti: false,
                required: true,
                control: control,
                errors: errors,
                options: locationsSelectList,
                defaultValue: locationFromAvailability.location,
                disabled: true,
                onChangeFnc: (e) => updateRooms(e, false),
                getOptionLabelFnc: (option) => option.name,
                getOptionValueFnc: (option) => option.id,
                styles: { hideMargin: true, width50: true },
              })}

              {renderSelectField({
                name: "room",
                label: t("Room"),
                placeholder: t("Select"),
                isMulti: false,
                required: true,
                control: control,
                errors: errors,
                options: roomsSelectList,
                defaultValue: locationFromAvailability.room,
                disabled: true,
                getOptionLabelFnc: (option) => option.name,
                getOptionValueFnc: (option) => option.id,
                styles: { hideMargin: true, width50: true },
              })}

              {renderSelectField({
                name: "repeatType",
                label: t("Repeat Type"),
                placeholder: t("Select"),
                isMulti: false,
                required: false,
                control: control,
                errors: errors,
                options: appointmentRepeatOptions(t),
                disabled: false,
                getOptionLabelFnc: (option) => t(option.label),
                getOptionValueFnc: (option) => option.value,
                styles: { hideMargin: true, width50: true },
              })}

              {renderInputField(
                "repeats",
                t("Repeat Count"),
                false,
                control,
                errors,
                "number",
                undefined,
                watch("repeatType") === null,
                { hideMargin: true, width50: true }
              )}

              <Col className="text-center mt-1" xs={12}>
                <Button type="submit" className="me-1" color="primary">
                  {t("Save")}
                </Button>

                <Button
                  color="secondary"
                  className="me-1"
                  outline
                  onClick={() => {
                    close();
                  }}
                >
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </UILoader>
      </Modal>
    </Fragment>
  );
};

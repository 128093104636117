/*eslint-disable */
import * as Sentry from "@sentry/react";
import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ThemeContext } from "./context/ThemeColors";
import { AuthProvider } from "./context/auth";
import { TenantProvider } from "./context/tenant";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
  dsn: "https://47cc24df7ca2957198825a3e36121c1d@o4508494339244032.ingest.de.sentry.io/4508494341800016",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const App = lazy(() => import("./App"));

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <TenantProvider>
          <ThemeContext>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeContext>
        </TenantProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (window.location.hostname === "panel.studioterapii.pl") {
  // Add Google Tag Manager script to <head>
  var gtmScript = document.createElement("script");
  gtmScript.innerHTML =
    "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':" +
    "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0]," +
    "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=" +
    "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);" +
    "})(window,document,'script','dataLayer','GTM-T4MTGTRW');";
  document.head.appendChild(gtmScript);

  // Add Google Tag Manager <noscript> iframe to <body>

  const gtmNoscript = document.createElement("noscript");
  gtmNoscript.innerHTML =
    '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T4MTGTRW" height="0" width="0" style="display:none;visibility:hidden"></iframe>';

  document.body.insertBefore(gtmNoscript, document.body.firstChild);
}

/* eslint-enable */
